import styles from './TodoInput.module.css';
import { ReactComponent as Plus } from '../../PlusIcon.svg';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
export default function TodoInput({ todos, setTodos }) {
  const [todo, setTodo] = React.useState({
    id: uuidv4(),
    done: false,
    task: '',
  });

  function handleSubmit(e) {
    e.preventDefault();
    setTodos([...todos, todo]);
    setTodo({ done: false, task: '', id: uuidv4() });
  }
  return (
    <form className={styles.input__wrapper} onSubmit={handleSubmit}>
      <label htmlFor="input__todo" className={styles.label__todo}>
        <input
          type="text"
          name="todo"
          id="input__todo"
          className={styles.input__todo}
          placeholder="Add a todo item"
          required
          value={todo.task}
          onChange={(e) => setTodo({ ...todo, task: e.target.value })}
        />
      </label>
      <button className={styles.btn__add_todo} aria-label="add to list">
        <Plus className={styles.btn__svg} />
      </button>
    </form>
  );
}
