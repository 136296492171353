import TodoInput from 'Components/TodoInput';
// import TodoItemsContainer from 'Components/TodoItemsContainer';
import React, { lazy, Suspense } from 'react';

const TodoContainer = lazy(() => import('Components/TodoItemsContainer'));
export default function TodoList() {
  const [todos, setTodos] = React.useState([]);

  return (
    <main>
      <TodoInput todos={todos} setTodos={setTodos} />
      <Suspense fallback={<p></p>}>
        <TodoContainer todos={todos} setTodos={setTodos} />
      </Suspense>
    </main>
  );
}
